.snapshot {
    .snapshot-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px 10px;

        .title {
            font-size: 18px;
            font-weight: 700;
        }

        .right-button {
            .download-button {
                display: flex;
                gap: 10px;
                border: solid 1px $border-color;
                padding: 5px 10px;
                border-radius: 40px;
                cursor: pointer;
            }
        }
    }

    .snapshot-body {
        padding: 20px;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;

            .image {
                width: 11vw;
                height: 6vw;
                overflow: hidden;

                img {
                    width: 100%;
                }

                .generate-image {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #FAFAFA;
                    cursor: pointer;
                    font-size: 14px;
                    gap: 8px;
                    padding: 10px 20px;
                    justify-content: center;

                    .button-image {
                        width: 1vw;
                    }

                    .button-text {
                        text-align: center;
                    }
                }
            }

            .col {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .title {
                    color: $gray-color;
                    font-size: 14px;
                }

                .value {
                    font-size: 16px;
                    font-weight: 500;
                    color: $black-color;
                }
            }
        }

        .snapshot-summary {
            display: flex;
            align-items: center;

            .pie-chart {
                flex: 1;
            }

            .modules-list {
                flex: 3;

                table {
                    tr {
                        th {
                            padding: 10px 30px;
                            color: $gray-color;
                            font-weight: 400;
                            border-bottom: solid 1px $gray-color;
                        }
                    }

                    .module_item {
                        .label-color {
                            display: flex;
                            gap: 10px;
                            font-size: 16px;
                            padding: 5px 20px;
                            text-align: center;

                            .color-box {
                                width: 20px;
                                height: 20px;
                                border-radius: 50px;
                            }
                        }

                        .label {
                            padding: 2px 20px;
                            text-align: center;
                        }
                    }

                }
            }
        }
    }
}

.loading-process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    gap: 20px;

    .pending-text {
        color: $label-color;
        font-size: 20px;
        font-weight: 500;
        opacity: 0.8;
    }

    .process-text {
        font-size: 20px;
        font-weight: 700;
    }
}

.snapshot-display-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .snapshot-display-group-table {
        flex: 1;
    }

    .snapshot-display-group-popup {
        flex: 1;
        padding: 20px;
        display: flex;
        box-shadow: 0px 0px 1.5px $black-color;
        flex-direction: column;
        gap: 20px;
        max-height: 40vh;
        overflow-y: scroll;

        .close-icon {
            position: fixed;
            right: 0;
            margin: 0px 31px;
            .icon {
                width: 27px;
                cursor: pointer;
            }
        }

        .snapshot-display-group-list {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .snapshot-display-group-title {
                font-size: 16px;
                font-weight: 600;
            }

            .snapshot-display-group-device-list {
                padding: 0px 0px 0px 5px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .snapshot-display-group-device-item {
                    font-size: 14px;
                    list-style: inside;
                    font-weight: 400;

                    &::marker {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
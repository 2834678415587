@font-face {
    font-family: 'Luckiest Guy';
    src: url(../../../assets/fonts/luckiestGuy/LuckiestGuy-Regular.ttf)format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'McLaren';
    src: url(../../../assets/fonts/mcLaren/McLaren-Regular.ttf)format('truetype');
    font-weight: 400;
    font-style: normal;
}

.template-container-4 {
    background-color: $pure-black;
    width: 100%;
    height: 100%;
    padding: 60px 30px 30px 30px;
    position: relative;

    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .grid-item {
            width: 1080px;
            margin: 0 auto;

            .top-section {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left-side-ice, .top-right-image {
                    width: 250px;
                    height: 300px;
                }

                .title-section {
                    width: 370px;
                    height: auto;

                    .menu-title {
                        border-top: 3px solid $template-4-yellow;
                    }

                    .curved-box {
                        margin-top: -157px;
                        width: 280px;
                        height: auto;
                    }
                }
            }
        }

        .bottom-section {
            width: 1100px;
            margin: 0 auto;

            .menu-lists {
                margin-top: 4rem;
                width: 40%;

                .menu-list-header {
                    display: flex;
                    justify-content: space-between;

                    .list-ice-icon {
                        width: 50px;
                        height: 50px;
                    }
                }

                ul.list-group {
                    li {
                        display: table;
                        padding: 0.5rem 0rem;

                        span {
                            display: table-cell;

                            &:first-child {
                                /* TITLE */
                                position: relative;
                                overflow: hidden;
                                /* Don't go underneath the price */

                                &:after {
                                    /* DASHES */
                                    content: "";
                                    position: absolute;
                                    bottom: 0.5em;
                                    /* Set as you want */
                                    margin-left: 0.3em;
                                    /* Keep same for the next span's left padding */
                                    width: 100%;
                                    border-bottom: 2.5px dotted $white-color;
                                }
                            }

                            +span {
                                /* PRICE */
                                text-align: right;
                                width: 1%;
                                /* Trick it */
                                vertical-align: bottom;
                                /* Keep Price text bottom-aligned */
                                padding-left: 0.3em;
                                // white-space: nowrap; /* Uncomment if needed */
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1550px) {
    .template-container-4 .row .grid-item {
        width: 70%;
    }

    .template-container-4 .row .bottom-section {
        width: 75%;
    }
}

@media screen and (max-width: 1280px) {
    .template-container-4 .row .grid-item {
        width: 980px;
    }

    .template-container-4 .row .bottom-section {
        width: 1000px;
    }
}
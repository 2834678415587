.template-container-7 {
    background-color: $pure-black;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    .inner-container {
        width: 100%;
        height: 238px;

        .flash-template {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;
            padding-left: 20px;
            overflow: hidden;

            .list-container {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .list-items {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .items {
                        display: flex;
                        align-items: center;

                        .item-image {
                            width: 150px;
                            height: 108px;
                        }

                        .text-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }
                    }
                }

                .right-text {
                    display: flex;
                    align-items: center;

                    .right-text-wrapper {
                        width: 174px;
                        text-align: center;
                    }
                }
            }

            .right-image-wrapper {
                position: absolute;
                z-index: -1;
                width: 195px;
                height: 238px;
                overflow: hidden;

                .right-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
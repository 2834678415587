@font-face {
    font-family: 'Big Shoulders Display';
    src: url(../../../assets/fonts/bigShoulderblack/BigShouldersDisplay-Black.ttf)format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../../../assets/fonts/montserrat/Montserrat-Regular.ttf)format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../../../assets/fonts/montserrat/Montserrat-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

.template-container-2 {
    background-color: $template-2-yellow;
    height: auto;

    .ff-template-contents {
        padding-top: 2rem !important;
        padding-bottom: 3rem !important;

        .title {
            text-align: center;
            margin: 0 auto;

            h2 {
                text-transform: uppercase;
                margin-bottom: 0.25rem;
                margin-top: 0;
            }

            span {
                font-weight: 500;
                font-size: 1.25rem !important;
            }
        }

        .menu-inner-card {
            background-color: $template-2-gray;
            width: 100%;
            max-width: 980px;
            margin: 1rem auto 0 auto;
            padding-bottom: 21rem !important;
            position: relative;
            padding-right: 3rem !important;
            padding-left: 3rem !important;

            .menu-title {
                text-align: center;
                margin-top: 1.25rem;
                display: flex;
                justify-content: space-between;

                h1 {
                    padding-top: 1rem;
                }
            }

            .ff-icon {
                width: 100px;
                height: 100px;
                display: block;
                position: relative;
                top: 30px;
                left: -15px;
                padding-left: 0.5rem;
            }

            .tomato-icon {
                display: block;
                width: 100px;
                height: 100px;
                margin-top: 40px;
                transform: rotate(-75deg);
            }

            .tomato-right-icon {
                display: block;
                width: 100px;
                height: 100px;
                position: relative;
                transform: rotate(-67deg);
            }

            .tomato-right-icon-2 {
                display: block;
                width: 100px;
                height: 100px;
                margin-top: 40px;
                transform: rotate(8deg);
            }

            .menu {
                display: flex;
                flex-wrap: wrap;
                gap: 2rem;
                margin-top: 3rem;
                color: $pure-white;

                .item {
                    background-color: transparent;
                    padding: 1rem;
                    box-shadow: none;
                    flex: 1 1 calc(50% - 1rem); /* This makes two items per row on large, medium, and small screens */

                    .ff-menu-container {
                        margin-bottom: 1.5rem !important;
                        color: $white-color;

                        .ff-menu-wrapper {
                            display: flex;
                            justify-content: space-between;

                            .ff-menu-item {
                                font-weight: 700;
                                word-break: break-word;
                            }
                            .ff-menu-price {
                                font-weight: 600;
                                margin-top: 15px;
                                margin-left: 10px;
                            }
                        }

                        .menu-description {
                            margin-right: 3rem !important;
                            font-weight: 500;
                            word-break: break-word;
                        }
                    }
                }
            }

            .right-image-wrapper {
                position: relative;

                .french-fries-icon {
                    display: block;
                    position: absolute;
                    height: 275px;
                    top: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                }

                .fruit-bare {
                    display: block;
                    position: absolute;
                    left: 215px;
                    z-index: 2;
                    top: 160px;
                    width: 120px;
                    height: 120px;
                }

                .tomato-soup {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    left: 380px;
                    top: 160px;
                    width: 120px;
                    height: 120px;
                }

                .tomato-soup-2 {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    right: 220px;
                    top: 160px;
                    width: 120px;
                    height: 120px;
                }
            }

        }
    }
}

/* Media query for screens wider than 1500px */
@media (min-width: 1500px) {
    .template-container-2 {
        .ff-template-contents {
            .menu-inner-card {
                max-width: 70%;
            }
        }
    }
}

/* Media query for screens wider than 1920px */
@media (min-width: 1920px) {
    .template-container-2 {
        .ff-template-contents {
            .menu-inner-card {
                max-width: 79%;
            }
        }
    }
}
@font-face {
    font-family: 'Nunito';
    src: url(../../../assets/fonts/nunito/Nunito-Regular.ttf)format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url(../../../assets/fonts/nunito/Nunito-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url(../../../assets/fonts/playfair/PlayfairDisplay-BoldItalic.ttf);
    // font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Playfair Display';
    src: url(../../../assets/fonts/playfair/PlayfairDisplay-Bold.ttf);
    // font-weight: 900;
    font-style: normal;

}

.template-container-9 {
    background-color: $template-9-green;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem 0 3rem;

    .template_9-inner-box {
        background-color: $template-9-white;
        width: 1020px;
        padding-bottom: 11rem;
        box-shadow: $box-shadow-dark-black 0px 5px 15px;
        position: relative;
        z-index: 2;
        overflow: hidden;

        .veg-salad-img {
            position: absolute;
            top: -20px;
            left: -20px;
            width: 250px;
            height: auto;
        }

        .egg-img {
            position: absolute;
            top: 30px;
            right: -50px;
            width: 200px;
            height: auto;
        }

        .pizza-img {
            position: absolute;
            left: 50%;
            bottom: -35px;
            transform: translateX(-50%);
            width: 20.5%;
            height: auto;
        }

        .menu-title {
            text-align: center;
            margin-bottom: 3.5rem;
        }

        .starters {
            text-align: center;
        }

        .common-row {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
            padding: 1.5rem 3.6rem;

            .list-group {
                margin-right: 2.5rem;
                list-style: unset;

                .list-group-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.5rem 0;
                }
            }

            .salads-container {
                position: absolute;
                left: -100px;
                bottom: -40px;
                display: block;
                z-index: -1;
                width: 300px;
                transform: rotate(169deg);
                height: 400px;
                background-color: #FDEAAF;
                border-radius: 50% 50% 50% 50% / 50% 50% 40% 40%;
            }
        }

        .second-row {
            @extend .common-row;
            display: grid;
            grid-template-columns: auto auto;

            .multiple-col {
                margin-top: 10px;
                padding: 0px 12px;
            }
        }

        .first-row {
            @extend .common-row;
            display: flex;
            flex-wrap: wrap;

            .single-col {
                flex: 0 0 auto;
                width: 50%;
                margin-top: 10px;
                padding: 0px 12px;
            }
        }
    }
}

@media screen and (min-width: 1550px) {
    .template-container-9 .template_9-inner-box {
        width: 65%;
        padding-bottom: 12%;
    }
}

/* Media query for screens wider than 1920px */
@media screen and (min-width: 1920px) {
    .template-container-9 .template_9-inner-box {
        width: 70%;
        padding-bottom: 13%;
    }
}
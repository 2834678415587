.template-container-5 {
    background-color: $pure-black;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    .inner-container {
        width: 100%;
        height: 360px;

        .bar-display {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 20px;
            position: relative;
            z-index: 2;

            .bar-display-container {
                display: flex;
                gap: 10px;
                justify-content: center;
                width: 100%;
                position: relative;

                .left-side {
                    display: flex;
                    align-items: center;
                    text-align: center;

                    .item-name {
                        width: 35%;
                        margin-bottom: 50px;
                    }

                    .item-images {
                        display: flex;
                        align-items: flex-end;
                        margin-left: 30px;

                        .item-image-wrapper {
                            margin-top: 41px;

                            .item-image-1 {
                                width: 330px;
                                height: 300px;
                            }
                        }

                        .item-image-2 {
                            width: 248px;
                            height: 195px;
                            margin-bottom: 10px;
                        }
                    }
                }

                //starts with right side
                .right-side {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: right;
                    margin-right: 25px;
                    margin-bottom: 90px;
                }

                .bottom-image-wrapper {
                    position: absolute;
                    bottom: -12px;
                    left: -22px;
                    z-index: -1;

                    .bottom-image {
                        width: 100vw;
                        height: 20%;
                    }
                }
            }
        }
    }

}
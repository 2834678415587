.template-container-6 {
  background-color: $pure-black;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  .inner-container {
    width: 100%;
    height: 360px;


    .d-mart-template {
      width: 100%;
      height: 100%;
      padding: 30px 70px;
      position: relative;
      display: flex;
      align-items: center;

      .logo-image {
        position: absolute;
        top: 20px;

        img {
          width: 126px;
          height: 94px;
        }
      }

      .item-list {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .items {
          display: flex;
          align-items: center;

          .item-image {
            width: 165px;
            height: 122px;
          }
        }
      }
    }
  }
}